//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'ErrorPage',

    methods: {
        goToHomePage() {
            this.$router.push("/");
        },
    },
}
